
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import Catalog from './components/Catalog.vue'
import Agencies from './components/Agencies.vue'
import { IBaseCost } from '@/interfaces/base-cost'
import { useRoute, useRouter } from 'vue-router'
import { useNotification } from '@/composables'
import useBaseCostReponsitories from '@/repositories/useBaseCostReponsitories'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Catalog,
    Agencies,
  },
  setup(props) {
    const breadcrumbs = ['Base Cost', 'Update']
    const content = computed(() => {
      return { breadcrumbs }
    })

    const route = useRoute()
    const router = useRouter()
    const formBaseCost = ref()
    const agencyComponent = ref()
    const catalogComponent = ref()
    const { error, success } = useNotification()
    const state = reactive({
      dialogVisible: false,
      isLoading: true,
      isLoadingAction: false,
      agencies: {
        add: ref([]),
        delete: ref([]),
      },
      catalogs: {
        add: ref([]),
        delete: ref([]),
      },
      ruleForm: {
        name: '',
        default: true,
        status: true,
      } as IBaseCost,
      rules: {
        name: [
          {
            required: true,
            message: 'Please input name',
            trigger: 'blur',
          },
        ],
      },
      groups: ref<any[]>([]),
      columns: ref([
        {
          title: 'Created',
          dataIndex: 'created',
        },
        {
          title: 'Name',
          dataIndex: 'created',
        },
        {
          title: 'Code',
          dataIndex: 'created',
        },
        {
          title: 'Balance',
          dataIndex: 'created',
        },
      ]),
      tableData: ref<any[]>([]),
      showModalAddSeller: ref<boolean>(false),
      baseCostSellers: ref<any[]>([]),
    })

    const { updateBaseCost, getBaseCost } = useBaseCostReponsitories()

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      state.isLoading = true
      let res = await getBaseCost(route.params.id as string)
      state.ruleForm = res
      state.isLoading = false
    }

    const onSave = async () => {
      formBaseCost.value.validate(async (valid: any) => {
        if (valid) {
          state.isLoadingAction = true

          let addedAgencies = [...agencyComponent.value.state.addedAgencies]
          let addedAgencyIds = addedAgencies.map((s: any) => s.id)

          let deletedAgencies = [...agencyComponent.value.state.deletedAgencies]
          let deletedAgencyIds = deletedAgencies.map((s: any) => s.id)

          let addedCatalogs = [...catalogComponent.value.state.addedCatalogs]
          let addedCatalogsIds = addedCatalogs.map((s: any) => s._id)

          let deletedCatalogs = [
            ...catalogComponent.value.state.deletedCatalogs,
          ]
          let deletedCatalogsIds = deletedCatalogs.map((s: any) => s._id)

          let params = {
            ...state.ruleForm,
            agencies: {
              add: addedAgencyIds,
              delete: deletedAgencyIds,
            },
            catalogs: {
              add: addedCatalogsIds,
              delete: deletedCatalogsIds,
            },
            // group: route.params.groupId,
          }
          let res = await updateBaseCost(params as IBaseCost)
          if (res.status === 201 || res.status === 200) {
            success('Update basecost success')
            getData()
          }
          state.isLoadingAction = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

    const onSaveAndClose = () => {
      formBaseCost.value.validate(async (valid: any) => {
        if (valid) {
          let addedAgencies = [...agencyComponent.value.state.addedAgencies]
          let addedAgencyIds = addedAgencies.map((s: any) => s.id)

          let deletedAgencies = [...agencyComponent.value.state.deletedAgencies]
          let deletedAgencyIds = deletedAgencies.map((s: any) => s.id)

          let addedCatalogs = [...catalogComponent.value.state.addedCatalogs]
          let addedCatalogsIds = addedCatalogs.map((s: any) => s._id)

          let deletedCatalogs = [
            ...catalogComponent.value.state.deletedCatalogs,
          ]
          let deletedCatalogsIds = deletedCatalogs.map((s: any) => s._id)

          let params = {
            ...state.ruleForm,
            agencies: {
              add: addedAgencyIds,
              delete: deletedAgencyIds,
            },
            catalogs: {
              add: addedCatalogsIds,
              delete: deletedCatalogsIds,
            },
            // group: route.params.groupId,
          }
          let res = await updateBaseCost(params as IBaseCost)
          if (res.status === 201 || res.status === 200) {
            success('Update basecost success')
            router.push({
              name: 'admin.baseCost',
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

    const onAddCatalogs = (catalogs: any) => {
      console.log(catalogs)
    }

    return {
      content,
      state,
      formBaseCost,
      agencyComponent,
      catalogComponent,
      route,
      onSave,
      onSaveAndClose,
      onAddCatalogs,
    }
  },
})
